import React from "react";
import Layout from "../components/layout";
import IntlText from "../components/intl-text";
import Button from "../components/button";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { NOT_FOUND_ALT } from "../constants/Constants";
import { withTheme } from "styled-components";
import PropTypes from "prop-types"

const NotFound = ({ theme }) => {
  const data = useStaticQuery(graphql`
    query imageNotFound {
      allFile(filter: {base: {eq: "notFound.png"}}) {
        edges {
          node {
            id
            base
            relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED,
                  aspectRatio: 1.5,
                  formats: [WEBP]
                )
              }
          }
        }
      }
    }
  `);
  const filterImage = data.allFile.edges.filter((i) => i.node.relativePath === theme?.notFoundImage)

  return (
    <Layout
      hideContact={true}
    >
      <div className="container my-5 row px-5 py-5">
        <div className="col-12 order-12 order-md-1 d-flex flex-column mt-4 pl-md-5 col-md-6 justify-content-center">
          <p className="display-4 font-weight-bold py-3"><IntlText id="notFound.text.first" /></p>
          <p className="h4 font-weight-light py-3 w-md-75"><IntlText id="notFound.text.second" /></p>
          <p className="h4 pb-3"><IntlText id="notFound.text.third" /></p>
          <div className="py-4">
            <Button size="normal" color={!theme.isDark ? "dark" : "light"}>
              <IntlText id="notFound.back" />
            </Button>
          </div>
        </div>
        <div className="col-12 col-md-6 order-1 order-md-12 mt-4 px-2 pl-2 d-flex justify-content-center align-items-center">
          <GatsbyImage image={getImage(filterImage[0].node.childImageSharp)} alt={NOT_FOUND_ALT} />
        </div>
      </div>
    </Layout>)
}

NotFound.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(NotFound)
